import React from "react";
import "../styles/index.css";
import Home from "./home";

// markup
const IndexPage = () => {
  return (
    <main>
      <Home />
    </main>
  );
};

export default IndexPage;
